import React, { useContext, useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { Link, navigate } from "gatsby";
import Skeleton from "react-loading-skeleton";
import PageWrapper from "../components/PageWrapper";
import LastSeen from "../components/LastSeen";
import { DataContext } from "../context/DataContext";
import { AuthContext } from "../context/AuthContext";
import { updateJob } from "../api";
import notify from "../utils/notify";

const DashboardAdminJobs = () => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const { jobs, getAllJobsList } = useContext(DataContext);
  const { auth } = useContext(AuthContext);

  const fetchJobs = async () => {
    await getAllJobsList();
    setLoading(false);
  };

  useEffect(() => {
    if (!auth || !auth?.admin) {
      navigate("/", { replace: true });
    } else {
      fetchJobs();
    }
    return () => setLoading(true);
  }, []);

  const handleJobExpired = async (job) => {
    try {
      setSubmitting(true);
      const { id, status } = job;
      let data = {
        status: "pending",
      };
      if (status != "expired") {
        data = {
          status: "expired",
        };
      }
      await updateJob(id, "admin", data);
      notify("Job status updated successfully", "success");
      setSubmitting(false);
      setLoading(true);
      await fetchJobs();
    } catch (error) {
      console.log(error.message);
      notify(error.message);
      setSubmitting(false);
    }
  };

  const handleDeleteJob = async (job) => {
    try {
      setSubmitting(true);
      const { id, status } = job;
      const data = {
        status: status === "archived" ? "pending" : "archived",
      };
      await updateJob(id, "delete", data);
      notify("Job updated successfully", "success");
      setSubmitting(false);
      setLoading(true);
      await fetchJobs();
    } catch (error) {
      console.log(error.message);
      notify(error.message);
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageWrapper
        headerConfig={{
          button: "profile",
          isFluid: true,
          bgClass: "bg-default",
          reveal: false,
        }}
      >
        <div className="dashboard-main-container mt-25">
          <div className="container">
            <div className="mb-8 mb-sm-18">
              <div className="row mb-7 align-items-center">
                <div className="col-lg-6 mb-lg-0 mb-4">
                  <h3 className="font-size-6 mb-0">Jobs</h3>
                </div>
                <div className="col-lg-6">
                  <div className="d-flex flex-wrap align-items-center justify-content-lg-end">
                    <Link
                      to="/post-job"
                      className="font-size-3 font-weight-bold text-red-2 text-uppercase"
                    >
                      New Job
                    </Link>
                  </div>
                </div>
              </div>
              <div className="bg-white shadow-8 rounded p-4 py-sm-7 px-sm-11">
                <div className="row">
                  <div className="col-12 order-2 order-xl-1">
                    <div className="">
                      <div className="table-responsive ">
                        <table className="table table-striped mt-7">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              >
                                Category
                              </th>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              >
                                Location
                              </th>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              >
                                Created
                              </th>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              >
                                Created by
                              </th>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              >
                                Bids
                              </th>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              >
                                Status
                              </th>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              ></th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading ? (
                              [1, 2, 3, 4, 5].map((index) => (
                                <tr
                                  key={index}
                                  className="border border-color-2"
                                >
                                  <th className="table-y-middle pl-6 border-0 py-7 min-width-px-235">
                                    <Skeleton width={200} height={26} />
                                  </th>
                                  <td className="table-y-middle py-7 min-width-px-135">
                                    <Skeleton width={120} height={26} />
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-135">
                                    <Skeleton width={120} height={26} />
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-125">
                                    <Skeleton width={100} height={26} />
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-100">
                                    <Skeleton width={100} height={26} />
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-80">
                                    <Skeleton width={30} height={26} />
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-80">
                                    <Skeleton width={70} height={26} />
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-80">
                                    <Skeleton width={70} height={26} />
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-80">
                                    <Skeleton width={100} height={26} />
                                  </td>
                                </tr>
                              ))
                            ) : jobs.length > 0 ? (
                              jobs.map((job, index) => (
                                <tr
                                  key={index}
                                  className="border border-color-2"
                                >
                                  <th
                                    scope="row"
                                    className="table-y-middle pl-6 border-0 py-7 min-width-px-235"
                                  >
                                    <div className="">
                                      <Link
                                        to={`/job-details/${job.id}`}
                                        state={{ id: job?.id }}
                                        className={`font-size-4 mb-0 ${job.status === "archived"
                                          ? "font-weight-normal text-gray"
                                          : "font-weight-semibold text-black-2"
                                          }`}
                                      >
                                        {job?.position}
                                      </Link>
                                    </div>
                                  </th>
                                  <td className="table-y-middle py-7 min-width-px-135">
                                    <h3
                                      className={`font-size-4 font-weight-normal ${job.status === "archived"
                                        ? "text-gray"
                                        : "text-black-2"
                                        } mb-0`}
                                    >
                                      {job?.category}
                                    </h3>
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-125">
                                    <h3
                                      className={`font-size-4 font-weight-normal ${job.status === "archived"
                                        ? "text-gray"
                                        : "text-black-2"
                                        } mb-0`}
                                    >
                                      {job?.location}
                                    </h3>
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-155">
                                    <h3
                                      className={`font-size-4 font-weight-normal ${job.status === "archived"
                                        ? "text-gray"
                                        : "text-black-2"
                                        } mb-0`}
                                    >
                                      <LastSeen
                                        date={new Date(job?.created.toDate())}
                                      />
                                    </h3>
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-205">
                                    <h3
                                      className={`font-size-4 font-weight-normal ${job.status === "archived"
                                        ? "text-gray"
                                        : "text-black-2"
                                        } mb-0`}
                                    >
                                      {job?.client}
                                    </h3>
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-80">
                                    <h3
                                      className={`font-size-4 font-weight-normal ${job.status === "archived"
                                        ? "text-gray"
                                        : "text-black-2"
                                        } mb-0`}
                                    >
                                      {job?.bids.length}
                                    </h3>
                                  </td>
                                  <td className="table-y-middle py-7">
                                    <h3
                                      className={`font-size-4 font-weight-normal ${job.status === "archived"
                                        ? "text-gray"
                                        : "text-black-2"
                                        } mb-0`}
                                    >
                                      {job?.status}
                                    </h3>
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-80 d-flex align-items-center">
                                    <Link
                                      to={`/job-details/${job.id}`}
                                      state={{ id: job?.id }}
                                      className="text-uppercase font-size-3 text-green focus-reset mr-4"
                                      data-toggle="tooltip"
                                      title="Details"
                                    >
                                      <i className="fa fa-eye"></i>
                                    </Link>
                                    <Link
                                      to={`/dashboard-admin-job-details/${job.id}`}
                                      state={{ id: job.id }}
                                      className="text-uppercase font-size-3 text-black-2 focus-reset ml-4"
                                      data-toggle="tooltip"
                                      title="Edit"
                                    >
                                      <i className="fa fa-edit"></i>
                                    </Link>
                                    {job.status === "archived" ? (
                                      <button
                                        type="button"
                                        onClick={() => handleDeleteJob(job)}
                                        className="border-0 bg-transparent text-uppercase font-size-3 text-red-2 focus-reset ml-4"
                                        data-toggle="tooltip"
                                        title="Undo delete"
                                      >
                                        {submitting ? (
                                          "..."
                                        ) : (
                                          <i className="fa fa-history"></i>
                                        )}
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        onClick={() => handleDeleteJob(job)}
                                        className="border-0 bg-transparent text-uppercase font-size-3 text-red-2 focus-reset ml-4"
                                        data-toggle="tooltip"
                                        title="Delete"
                                      >
                                        {submitting ? (
                                          "..."
                                        ) : (
                                          <i className="fa fa-trash-alt"></i>
                                        )}
                                      </button>
                                    )}
                                    {job.status === "expired" ? (
                                      <button
                                        type="button"
                                        onClick={() => handleJobExpired(job)}
                                        className="border-0 bg-transparent text-uppercase font-size-3 text-red-2 focus-reset ml-4"
                                        data-toggle="tooltip"
                                        title="Undo expired"
                                      >
                                        {submitting ? (
                                          "..."
                                        ) : (
                                          <i className="fa fa-undo-alt"></i>
                                        )}
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        onClick={() => handleJobExpired(job)}
                                        className="border-0 bg-transparent text-uppercase font-size-3 text-green focus-reset ml-4"
                                        data-toggle="tooltip"
                                        title="Expire Job"
                                      >
                                        {submitting ? (
                                          "..."
                                        ) : (
                                          <i className="fa fa-hourglass-end"></i>

                                        )}
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr className="border border-color-2">
                                <td
                                  colSpan={8}
                                  className="pl-6 border-0 py-7 pr-0"
                                >
                                  <h4 className="font-size-4 mb-0 font-weight-normal text-black-2">
                                    No jobs found at the moment
                                  </h4>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default DashboardAdminJobs;
